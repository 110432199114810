import Cookie from '@/utils/cookie.js';
import moment from 'moment';

export default ({
  auth(to, from, next) {
    let token = JSON.parse(Cookie.get('__token'));

    // check if has token 
    if (!token) {
      next('/auth/login');
    }

    // check if token is valid
    if (moment().diff(token.created_at, 'minutes') >= 30) {
      // todo: request to validate if user token is valid
    }
  },
  guest(to, from, next) {
    let token = JSON.parse(Cookie.get('__token'));

    if (token) {
      next('/dashboard');
    }
  },
  admin(to, from, next) {
    let user = JSON.parse(Cookie.get('__user'));

    if (user.role != 666) {
      next('/dashboard');
    }
  }
});
