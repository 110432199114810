<template>
  <header v-show="!this.$route.meta.hide">
    <router-link to="/" class="logo"> xanax<span>666</span> </router-link>

    <div class="menu-links">
      <router-link to="/dashboard/buy" class="buy_link">
        buy
      </router-link>

      <template v-if="user.id">
        <router-link v-if="user.role == 666" to="/admin/dashboard">
          admin
        </router-link>

        <router-link to="/dashboard/cloud" class="hide_mobile">
          Cloud
        </router-link>

        <router-link to="/dashboard">
          {{ user.name }}
        </router-link>

        <img
          :src="user.avatar"
          alt="xanax666 avatar"
          class="avatar"
        >
      </template>

      <template v-else>
        <router-link to="/auth/login">
          Login
        </router-link>
      </template>
    </div>
  </header>
</template>

<script>
  export default {
    name: "Navbar",
    props: {
      user: Object
    }
  };
</script>

<style scoped>
  header {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 70px;
    height: unset;
  }

  @media only screen and (max-width: 665px) {
    header {
      padding: 10px 0px;
      justify-content: space-evenly;
    }

    .avatar {
      display: none;
    }
  }

  .logo {
    color: var(--secondary-fore-color);
    font-weight: 600;
    font-size: 25px;
  }

  .logo span {
    color: var(--text-color);
  }

  .buy_link {
    background: #a21fa2;
    padding: 3px 15px!important;
    color: rgb(245, 245, 245)!important;
    border-radius: 20px;
    margin: 0 15px 0 0;
    text-align: center;
  }

  .menu-links {
    display: flex;
    align-items: center;
  }
  
  .menu-links a {
    text-decoration: none;
    color: var(--fore-color);
    padding: 0 15px 0 0;
    margin-top: -2px;
    text-transform: lowercase;
    font-weight: 500;
  }

  .menu-links a .button {
    background-color: var(--header-back-color);
  }

  .avatar {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50px;
  }
</style>
