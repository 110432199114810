import { createRouter, createWebHistory } from 'vue-router'
import Guard from '@/middleware/index.js';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
    meta: {
      guards: []
    }
  },
  {
    path: '/auth/login',
    name: 'auth.login',
    component: () => import('@/views/auth/Login'),
    meta: {
      guards: ['guest'],
      hide: true
    }
  },
  {
    path: '/auth/register',
    name: 'auth.register',
    component: () => import('@/views/auth/Register'),
    meta: {
      guards: ['guest'],
      hide: true
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/user/Dashboard'),
    meta: {
      guards: ['auth']
    }
  },
  {
    path: '/dashboard/cloud',
    name: 'cloud',
    component: () => import('@/views/user/Cloud'),
    meta: {
      guards: ['auth']
    }
  },
  {
    path: '/dashboard/buy',
    name: 'buy',
    component: () => import('@/views/user/Buy'),
    meta: {
      guards: ['auth']
    }
  },
  {
    path: '/admin/dashboard',
    name: 'admin.index',
    component: () => import('@/views/admin/Dashboard'),
    meta: {
      guards: ['auth', 'admin']
    }
  },
  {
    path: '/profile/:id',
    name: 'profile.index',
    component: () => import('@/views/Profile'),
    meta: {
      guards: []
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: () => import('@/views/Home'),
    meta: {
      guards: []
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === 'error') {
    return next('/');
  }

  to.meta.guards.forEach((guard) => {
    Guard[guard](to, from, next);
  });

  next();
})

export default router;
