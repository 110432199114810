<template>
  <footer class="text-center" v-show="!this.showFooter()">
    <p>
      <b>we are not responsible for vac' bans or other types of punishment.</b> <br>
      xanax666 | website developed by <a class="a_link2" href="https://github.com/danielspinard" target="__blank">'spi</a>
    </p>
  </footer>
</template>

<script>
  export default {
    name: 'Footer',
    methods: {
      showFooter() {
        return this.$route.meta.hide;
      }
    }
  };
</script>

<style scoped>
  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: calc(2 * var(--universal-padding)) 0;
    border: 0;
  }

  footer p {
    opacity: .5;
  }

  footer small {
    color: var(--secondary-fore-color);
  }
</style>