import CryptoJS from "crypto-js";

const cryptSecret = "xanax666@spi";

export default {
  encode(payload) {
    return CryptoJS.AES.encrypt(
      JSON.stringify(payload),
      cryptSecret
    ).toString();
  },
  decode(payload) {
    let decode = '';
  
    try {
      decode = CryptoJS.AES
        .decrypt(payload, cryptSecret)
        .toString(CryptoJS.enc.Utf8);
    } catch (error) {
      decode = false;
    }

    return decode;
  },
};
