import { createStore } from 'vuex'
import Cookie from '@/utils/cookie.js';
import moment from 'moment';

export default createStore({
  state: {
    user: JSON.parse(Cookie.get('__user') ?? '{}'),
    token: Cookie.get('__token')
  },
  mutations: {
    SET_USER: (state, user) => {
      Cookie.set('__user', user);
      Cookie.set('__token', {
        created_at: moment().format(),
        sanctum: user.token ?? ''
      });

      state.user = user;
    }
  },
  actions: {
    setUser({commit}, user) {
      commit('SET_USER', user);
    }
  },
  getters: {
    getUser: (state) => {
      return state.user;
    }
  }
});
