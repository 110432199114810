<template>
  <div id="loader">
    <img src="/loader.gif">
  </div>

  <div id="vue">
    <Header :user="user" />

    <div id="view">
      <router-view/>
    </div>

    <Footer />
  </div>
</template>

<script>
  import Header from '@/components/Header';
  import Footer from '@/components/Footer';

  export default {
    name: 'App',
    components: {
      Header,
      Footer
    },
    data() {
      return {
        user: this.$store.getters.getUser
      }
    }
  };
</script>

<style global>
  #vue {
    position: relative;
    min-height: 100vh;
  }

  #view {
    padding-bottom: 6.5rem;
    /* position: relative; */
    /* min-height: 100vh; */
  }
</style>
