import Cookie from "js-cookie";
import Crypt from "@/utils/crypt.js";

export default {
  set(key, value) {
    Cookie.set(key, Crypt.encode(value));
  },
  get(key) {
    return Crypt.decode(Cookie.get(key));
  },
};
